@tailwind base;
@tailwind components;
@tailwind utilities;

// COMMON
@import './common/tailwind/index.scss';
@import './common/reset.scss';
@import './common/helpers.scss';
@import './common/mixin.scss';
@import './common/color.scss';
@import './common/fonts.scss';

// PAGES
@import './pages/app';
@import './pages/404.scss';

/** START CONTAINERS **/
@import './containers/index.scss';
@import './containers/verifyOtp.scss';
@import './containers/payment.scss';
@import './containers/paymentProcess.scss';
@import './containers/orderReceipt.scss';
@import './containers/orderConfirm.scss';
@import './containers/search.scss';
@import './containers/productDescPage.scss';
@import './containers/deliveryList.scss';
@import './containers/spotlight.scss';
@import './containers/spotlightCitySearch.scss';
@import './containers/spotlightSearch.scss';
@import './containers/spotlightCategoryStoresList.scss';
@import './containers/cardPayment.scss';
@import './containers/thankyou.scss';
@import './containers/home.scss';
@import './containers/orders.scss';
@import './containers/addDelivery.scss';
@import './containers/deliveryDetails.scss';
@import './containers/deliveryList.scss';
@import './containers/orderStatus.scss';
@import './containers/productDescPage.scss';
@import './containers/aboutUs.scss';
@import './containers/additionalPage.scss';
@import './containers/blogPage.scss';
@import './containers/categories.scss';
@import './containers/collections.scss';
@import './containers/productCustomization.scss';
/** END CONTAINERS **/

/** START COMPONENTS **/
// common -------------------
@import './components/common/toast.scss';
@import './components/common/modal.scss';
@import './components/common/storeLogo.scss';
@import './components/common/iconButton.scss';
@import './components/common/layout.scss';
@import './components/common/customBtn.scss';
@import './components/common/storeNameLabel.scss';
@import './components/common/incDecButton.scss';
@import './components/common/floatingButton.scss';
@import './components/common/input.scss';
@import './components/common/LoaderIcon.scss';
@import './components/common/tooltip.scss';
@import './components/leadgen.scss';
@import './components/commonHeaderIconsMobile.scss';
@import './components/loginComponent.scss';
@import './components//exitGatingModal.scss';

//Premium
@import './components/premium/authModal.scss';
@import './components/premium/orderInstructionModal.scss';
@import './components/premium/multiImageViewer.scss';
@import './components/premium/subHeader.scss';
@import './components/premium/loader.scss';
@import './components/premium/orderConfirmLoader.scss';
@import './components/premium/quickView.scss';
@import './components/premium/header8.scss';
@import './components/premium/header9.scss';
@import './components/premium/header10.scss';
@import './components/premium/header11.scss';
@import './components/premium/header12.scss';
@import './components/premium/header14.scss';
@import './components/premium/footer2.scss';
@import './components/premium/footer3.scss';
@import './components/premium/navigationItems.scss';
@import './components/premium/collectionHeaderDropdown.scss';

// remaining ----------------
@import './components/brandFooter.scss';
@import './components/footerStoreInfo.scss';
@import './components/haveSomethingInMind.scss';
@import './components/productHeader.scss';
@import './components/storeDescription.scss';
@import './components/addressAndContactBtnStrip.scss';
@import './components/paperList.scss';
@import './components/createPaperList.scss';
@import './components/productItem.scss';
@import './components/viewCartPopUp.scss';
@import './components/categoryPopUp.scss';
@import './components/productDescPopUp.scss';
@import './components/authModal.scss';
@import './components/orderItem.scss';
@import './components/orderInstructionModal.scss';
@import './components/cashPayConfirmModal.scss';
@import './components/multiImageViewer.scss';
@import './components/pdItemCardLoader.scss';
@import './components/radioGroup.scss';
@import './components/searchBar.scss';
@import './components/dukaanCard.scss';
@import './components/dukaanInfoPopUp.scss';
@import './components/spotlightHeader.scss';
@import './components/citySearchedItem.scss';
@import './components/sideDrawer.scss';
@import './components/athsPopUp.scss';
@import './components/toggleTextSwitch.scss';
@import './components/guideLines.scss';
@import './components/orderConfirmLoader.scss';
@import './components/variantModal.scss';
@import './components/variantRadioGroup.scss';
@import './components/variantWillChooseRepeatModal.scss';
@import './components/variantSelectedListModal.scss';
@import './components/cartValidationErrorMsgModal.scss';
@import './components/paymentOptions.scss';
@import './components/desktopPaymentModal.scss';
@import './components/paymentModal.scss';
@import './components/cardPaymentBox.scss';
@import './components/promoList.scss';
@import './components/promoApplyWidget.scss';
@import './components/couponCards.scss';
@import './components/heroBanner.scss';
@import './components/clHaveSomeThingInMind.scss';
@import './components/fullScreenMultiMediaViewer.scss';
@import './components/inaccessibleLayer.scss';
@import './components/redirectionToWebConsoleModal.scss';
@import './components/paymentPromoCouponDetailsModal.scss';
@import './components/itemImage.scss';
@import './components/categoryWidget/verticalCategoryWidget.scss';
@import './components/categoryWidget/horizontalCategoryWidget.scss';
@import './components/categoryWidget/cards.scss';
@import './components/categoryWidget/categoryWidget9.scss';
@import './components/categoryHeader/categoryHeader.scss';
@import './components/categoryProductDisplayWidget/horizontalWidget.scss';
@import './components/filterAndSorting/filterAndSorting.scss';
@import './components/emiModal.scss';
@import './components/emiCardSelection.scss';
@import './components/bankOfferModal.scss';
@import './components/emiCardPayment.scss';
@import './components/customRadio.scss';
@import './components/LogoutModal.scss';
@import './components/Headers/Header.scss';
@import './components/Footer.scss';
@import './components/MiddleBody6.scss';
@import './components/ReasonModal.scss';
@import './components/collectionWidget/collectionWidget1.scss';
@import './components/collectionCards/collectionCards.scss';
@import './components/CollectionCategoryProductWidget/CollectionCategoryProductWidget1.scss';
@import './components/CollectionCategorySideRender/CollectionCategorySideRender.scss';
@import './components/premium/storeNameComp.scss';
@import '../containers/ProductDescription/Components/ImageDescriptionModal/ImageDescriptionModal.scss';
@import '../styles/containers/sortAndFilterCollection.scss';

// Store Policies
@import './components/storePolicies.scss';
@import './components/emptyWidgetContainer/emptyWidget.scss';
/** END COMPONENTS **/

// EXTERNAL CSS
@import './common/tachyons/index.scss';

// Hello Bar CSS
@import './components/helloBar.scss';

@import './components/notifyProduct.scss';
@import '../components/SortingAndFiltersTab/SortingAndFiltersTab.scss';
@import '../components/DesignSystem/MiddleBody/MiddleBody5/components/SortAndFilteredItems/SortAndFilteredItems.scss';
@import '../components/SortingAndFiltersTab/Components/SortByCtaDesktop/SortByCtaDesktop.scss';
@import '../components/SortingAndFiltersTab/Components/SortByCtaMobile/SortByCtaMobile.scss';
@import '../components/DesignSystem//MiddleBody/MiddleBody6/FilterDrawerMidd6/FilterDrawerMidd6.scss';
@import '../components/DesignSystem/MiddleBody/MiddleBody6/FilteredAndSortItems/FilteredAndSortItems.scss';

@import '../containers/OrderStatus/styles.scss';

@import '../components/WidgetMaker/WidgetDnD/builder-sprite.scss';
@import '../components/WidgetMaker/WidgetDnD/customDropdown.scss';
@import '../components/DesignSystem/Widgets2.0/Carousel/CarouselWidgetSliderStyles.scss';
